@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, .App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

main {
  flex-grow: 1;
}

@layer components {
  .btn-primary {
    @apply mt-4 py-2 text-black font-semibold rounded-lg shadow-md transition duration-700 ease-in-out hover:text-red-500 hover:bg-pink-200;
  }
}

@layer components {
  .btn-secondary {
    @apply m-2 p-2 text-xs md:text-sm bg-white font-semibold rounded-lg shadow-md transition duration-700 ease-in-out hover:text-red-400 hover:bg-pink-100;
  }
}

@layer components {
  .project-card {
    @apply p-4 md:m-6 h-1/5 md:w-96 w-80
  }
}

@layer components {
  .project-card > img {
    @apply border-2 border-black w-96 h-56 shadow-2xl
  }
}

@layer components {
  .stack-icons > img {
    @apply w-8 h-8 md:w-12 md:h-12
  }
}

@layer components {
  .icons > img {
    @apply w-16 h-16 md:w-20 md:h-20
  }
}

@layer components {
  .profile {
    @apply w-64 h-64 md:w-[26rem] md:h-[26rem] lg:w-[30rem] lg:h-[30rem] border-pink-200 border-4 my-3 shadow-xl
  }
}

@layer components {
  .form {
    @apply flex flex-col w-5/6 md:w-3/5 items-center justify-center shadow-xl rounded-md drop-shadow-md py-12 md:py-20 mb-16 mt-10 text-sm
  }
}

@layer components {
  .form > div > label {
    @apply font-semibold md:text-lg mx-3
  }
}

@layer components {
  .form > div > input[type='text'] {
    @apply border-2 border-pink-100 md:h-10 md:w-96 h-8 w-64 overflow-x-auto ps-2 mb-10 rounded py-2 mt-3 md:mt-0
  }
}

@layer components {
  .form > div > textarea {
    @apply border-2 border-pink-100 md:h-40 md:w-96 h-20 w-64 overflow-y-auto ps-2 py-2 rounded mt-3 md:mt-0
  }
}

@layer components {
  .modal {
    @apply fixed flex flex-col items-center top-0 left-0 w-full h-full bg-stone-500 bg-opacity-80 shadow-2xl
  }
}

@layer components {
  .modal-content {
    @apply fixed top-1/3 w-5/6 md:w-3/5 md:text-2xl text-center border-2 py-10 px-3 opacity-100 bg-white font-semibold
  }
}

.hamburger {
  display: none;
}

@media (max-width: 767px) {
  .hamburger {
    display: block;
  }
}